.countinbox {
    border-radius: 10px;
    padding: 20px 25px;
    height: 110%;
    position: relative;
    transform: scale(0.9);
    box-shadow: 0px -3px 15px 0px #9c46b1ad inset;
    background-size: 500%;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;

    &:hover {
      box-shadow: 2px 3px 15px 2px #b146b1ad;
    }

    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0px;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      z-index: -2;
      opacity: 0.6;
      mix-blend-mode: exclusion;
    }
  }
.ref-p{
    margin-bottom: 5px;
}
  .ref-font{
    font-family: "Zen Dots", Sans-serif;
    background: linear-gradient(to bottom right, #ec4899, #8b5cf6, #3b82f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    font-size: 1.2rem; 
    font-weight: 400;
    margin-bottom: 0;
 
  }
  .image-ref{
    width: 50px;
    height: auto;
  }

  
body .table {
    background: transparent;
    color: #ffffff;
    th {
      backdrop-filter: blur(15px);
      box-shadow: rgba(176, 56, 180, 0.486) 0px 20px 20px -20px inset;
      margin-bottom: 30px !important;
      white-space: nowrap;
      font-weight: 500;
    }
  
    tr {
      backdrop-filter: blur(15px);
      box-shadow: rgba(136, 61, 155, 0.678) 0px 20px 20px -20px inset;
      margin-bottom: 20px;
      white-space: nowrap;
      border-image: initial;
      border-radius: 10px;
      color: #dbdbdb ;
  
      &:nth-last-child(1) {
        td {
        }
      }
    }
  }

  .table {
    --bs-table-color-type: initial;
    --bs-table-bg-type: initial;
    --bs-table-color-state: initial;
    --bs-table-bg-state: initial;
    --bs-table-color: #ffffff;
    --bs-table-bg: transparent;
    --bs-table-border-color: none;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: transparent;
    --bs-table-striped-bg: transparent;
    --bs-table-active-color: transparent;
    --bs-table-active-bg: transparent;
    --bs-table-hover-color: transparent;
    --bs-table-hover-bg: none;
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border-color: none;
}
.table>:not(caption)>*>* {
    color: #dfdcdc;
    padding: 1rem .5rem;
}

tbody, td, tfoot, th, thead, tr {

    border-style: none;

}