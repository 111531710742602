* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "icomoon";
  src: url("../Fonts/icomoon.eot");
  src: url("../Fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../Fonts/icomoon.woff2") format("woff2"), url("../Fonts/icomoon.woff") format("woff"), url("../Fonts/icomoon.ttf") format("truetype"), url("../Fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
a {
  text-decoration: none;
}

body {
  background-color: black;
  color: #fff;
  font-family: "Inter", sans-serif;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 15px;
}

.btn-main {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 48px;
  padding: 12px 32px;
  border-radius: 12px;
  position: relative;
  color: #fff;
}
.btn-main::before {
  position: absolute;
  content: "";
  top: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(90deg, #f54097 0%, #ef369b 7.69%, #e92c9f 15.38%, #e122a4 23.08%, #d918a9 30.77%, #d00dae 38.46%, #c603b3 46.15%, #ba00b9 53.85%, #ad01be 61.54%, #9f07c4 69.23%, #8e10c9 76.92%, #7a18cf 84.62%, #6220d4 92.31%, #3f26d9 100%), linear-gradient(45deg, #f54097 0%, #3f26d9 100%);
  bottom: -1px;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 12px;
}
.btn-main::after {
  content: "";
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
  border-radius: 12px;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
}

.crl-btn {
  background: linear-gradient(45deg, rgba(245, 64, 151, 0.337254902) 0%, rgba(62, 38, 217, 0.431372549) 100%);
}

.copyRefferalCode {
  background: linear-gradient(45deg, rgba(245, 64, 151, 0.5137254902) 0%, rgba(62, 38, 217, 0.4745098039) 100%);
  padding: 5px;
  color: white;
  border: none;
}

.copy-icon {
  filter: invert(1);
  margin-left: 5px;
}

span {
  position: relative;
  z-index: 2;
}

.banner-area {
  background: url(../Images/gradient1.png);
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  min-height: 90vh;
}

.btn-main:hover::before {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
}

.info-group h1 {
  color: #ffffff;
  font-family: "Zen Dots", Sans-serif;
  font-size: 70px;
  font-weight: 400;
  margin-bottom: 0px;
}
.info-group h1 span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
}
.info-group p {
  margin-bottom: 10px;
  text-align: justify;
}

.banner-img-group {
  text-align: end;
}
.banner-img-group img {
  width: 100%;
  height: auto;
  max-width: 540px;
}

header {
  position: absolute;
  width: 100%;
}
header img {
  width: 80px;
  height: auto;
}

[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nft-headline {
  margin-top: 20px;
  font-family: "Zen Dots", Sans-serif;
  font-size: 45px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 40px;
}
.nft-headline span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
}

.pricebtn {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
}

.padding-box {
  padding-bottom: 25px;
}

.element-container {
  width: 100%;
}

.inner-box {
  transition: 250ms all linear 0ms;
  position: relative;
  padding: 29px;
  position: relative;
  transition: 0.3s all ease-in-out;
  background: #07000a;
  border: 2.10333px solid rgba(218, 72, 255, 0.4);
  box-shadow: inset 0px 0px 27.3156px rgba(218, 72, 255, 0.6);
  -webkit-clip-path: polygon(0 28px, 28px 0, calc(100% - 28px) 0, 100% 28px, 100% calc(100% - 28px), calc(100% - 28px) 100%, 28px 100%, 0% calc(100% - 28px), 0% 28px);
          clip-path: polygon(0 28px, 28px 0, calc(100% - 28px) 0, 100% 28px, 100% calc(100% - 28px), calc(100% - 28px) 100%, 28px 100%, 0% calc(100% - 28px), 0% 28px);
}

.inner-box:hover {
  transform: translatey(-10px);
  border-color: #c004de;
  cursor: pointer;
}
.inner-box:hover .boder i {
  color: #c004de;
}
.inner-box:hover .pxl-item-content {
  top: 0%;
}
.inner-box:hover img.eye {
  display: none;
}

.boder {
  position: absolute;
}
.boder i {
  font-size: 73px;
  color: rgba(192, 4, 222, 0.2);
  transition: 250ms all linear 0ms;
}

.bd1 {
  top: 0;
  left: -1px;
}

.bd2 {
  top: 0;
  right: -1px;
  transform: rotate(180deg) rotatex(180deg);
}

.bd3 {
  bottom: 0;
  right: -1px;
  transform: rotate(180deg) rotatex(0deg);
}

.bd4 {
  bottom: 0;
  left: -1px;
  transform: rotate(0deg) rotatex(180deg);
}

.icon-stroke:before {
  content: "\e914";
}

.wrap-content {
  -webkit-clip-path: polygon(0 28px, 28px 0, calc(100% - 28px) 0, 100% 28px, 100% calc(100% - 28px), calc(100% - 28px) 100%, 28px 100%, 0% calc(100% - 28px), 0% 28px);
          clip-path: polygon(0 28px, 28px 0, calc(100% - 28px) 0, 100% 28px, 100% calc(100% - 28px), calc(100% - 28px) 100%, 28px 100%, 0% calc(100% - 28px), 0% 28px);
  position: relative;
  overflow: hidden;
}

.pxl-item-image {
  display: flex;
  justify-content: center;
}
.pxl-item-image img {
  height: auto;
  width: 240px;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.copyimg {
  height: auto;
  width: 30px;
}

.pxl-item-content {
  margin-bottom: 0;
  padding: 24px 18px;
  position: absolute;
  top: calc(100% - 70px);
  left: 0;
  right: 0;
  color: #fff;
  height: 100%;
  text-align: center;
  font-family: "Zen Dots", sans-serif;
  transition: 250ms all linear 0ms;
  text-transform: capitalize;
  background: rgba(92, 3, 106, 0.15);
}
.pxl-item-content h3 {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

img.eye {
  position: absolute;
  right: 19px;
  height: auto;
  width: 25px;
  filter: invert(1);
}

.btn-primary {
  border-radius: 10px;
  --bs-btn-color: #fff;
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #b40aca;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.historysec {
  padding-block: 50px;
}
.historysec nav {
  justify-content: space-around;
  align-items: center;
  padding-block: 15px;
}
.historysec table thead tr {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%);
  overflow: hidden;
  justify-content: space-between;
}
.historysec table {
  position: relative;
  overflow: scroll;
}
.historysec table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.historysec .page-link {
  background-color: transparent;
  color: #fff;
}

.history-headline {
  font-family: "Zen Dots", Sans-serif;
  font-size: 45px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 40px;
}
.history-headline span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
}

.table-container {
  overflow-x: auto;
  width: 100%;
  /* or set any width you want */
}
.table-container table {
  min-width: 600px; /* adjust this value according to the minimum width you want for your table */
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  border: 1px solid #ddd; /* Adjust the border style and color as needed */
}

.carousel-indicators {
  margin-bottom: 10px;
  position: absolute;
  /* Adjust this value as needed to place the indicators at the desired position */
  bottom: auto;
}

.carousel-indicators button::before {
  display: none;
}

.carousel-indicators [data-bs-target] {
  text-indent: 0px;
  color: white;
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 100px;
  height: 40px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  /* cursor: pointer; */
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 0;
  opacity: 1;
  transition: opacity 0.6s ease;
}

.carousel-indicators button.active {
  background-color: transparent;
  background-image: linear-gradient(128deg, #f54097 0%, #3f26d9 100%); /* Change this to match your design */
}

.zm_footer {
  background: #151116;
  box-shadow: inset 0 10px 20px -20px #fff;
  padding: 20px 0;
  position: relative;
}

.zm_footer-social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  justify-content: flex-end;
}

.zm_footer-copyright-text p {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .banner-img-group {
    text-align: center;
  }
  .banner-img-group img {
    width: 100%;
    height: auto;
    max-width: 460px;
  }
}
.info-group h1 {
  color: #ffffff;
  font-family: "Zen Dots", Sans-serif;
  font-size: 70px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 140px;
}

.nft-headline {
  margin-top: 80px;
  font-family: "Zen Dots", Sans-serif;
  font-size: 40px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 40px;
}
.nft-headline span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
}

.nfthis {
  margin-top: 90px;
}

@media (max-width: 768px) {
  .info-group h1 {
    font-size: 40px;
  }
  .table-wrap {
    overflow-x: auto;
  }
  .zm_footer-copyright-text {
    text-align: center;
  }
  .zm_footer-card {
    margin-top: 10px;
  }
  .zm_footer-social {
    gap: 30px 20px;
    justify-content: center;
    margin-top: 0px;
  }
}
@media (max-width: 550px) {
  .nav-btn-area .btn-main {
    padding: 10px 10px;
  }
}
@media (max-width: 365px) {
  .nav-btn-area .btn-main {
    padding: 6px 3px;
  }
  header img {
    width: 50px;
    height: auto;
  }
  .info-group h1 {
    color: #ffffff;
    font-family: "Zen Dots", Sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 58px;
    margin-bottom: 20px;
    margin-top: 140px;
  }
  .nft-headline {
    margin-top: 70px;
    font-family: "Zen Dots", Sans-serif;
    font-size: 35px;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 40px;
  }
  .nft-headline span {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.00000001);
  }
  .pxl-item-content h3 {
    font-size: 20px;
  }
}
@media (max-width: 300px) {
  .nav-btn-area .btn-main {
    padding: 2px 1px;
  }
}/*# sourceMappingURL=Style.css.map */